<template>
  <div class="d-flex flex-wrap flex-stack mb-6">
    <h3 class="fw-bolder my-2 text-blue">Mis ubicaciones</h3>
  </div>

  <div class="row g-6 overflow-auto">
    <form @submit.prevent="submit" class="h-100 p-3 overflow-hidden">
      <div class="card h-100">
        <div class="card-body pt-9 pb-4 fondo h-100 rounded-3">
          <div
            class="row justify-content-end my-lg-20 align-items-center h-100"
          >
            <div class="col-md-4 col-sm-12 my-lg-20">
              <div class="d-flex h-100">
                <div class="card p-5 w-100">
                  <div>
                    <h4 class="text-blue text-center">
                      Agregar nueva ubicación
                    </h4>
                  </div>
                  <div class="card-body p-2">
                    <div class="my-2">
                      <select
                        class="form-select bg-gray-input border-0 text-gray"
                        id="paisId"
                        v-model="model.paisId"
                        name="paisId"
                      >
                        <option disabled value="">País</option>
                        <option
                          v-for="(pais, i) in paises"
                          :key="i"
                          :value="pais._id"
                        >
                          {{ pais.name }}
                        </option>
                      </select>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="paisId" />
                        </div>
                      </div>
                    </div>
                    <div class="my-2">
                      <select
                        class="form-select bg-gray-input border-0 text-gray"
                        aria-label="Default select example"
                        id="departamentoId"
                        v-model="model.departamentoId"
                        name="departamentoId"
                      >
                        <option disabled value="">Departamento</option>
                        <option
                          v-for="(departamento, i) in departamentos"
                          :key="i"
                          :value="departamento.id"
                        >
                          {{ departamento.name }}
                        </option>
                      </select>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="departamentoId" />
                        </div>
                      </div>
                    </div>
                    <div class="my-2">
                      <select
                        class="form-select bg-gray-input border-0 text-gray"
                        aria-label="Default select example"
                        id="ciudadId"
                        v-model="model.ciudadId"
                        name="ciudadId"
                      >
                        <option disabled value="">Ciudad</option>
                        <option
                          v-for="(ciudad, i) in ciudades"
                          :value="ciudad.id"
                          :key="i"
                        >
                          {{ ciudad.name }}
                        </option>
                      </select>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="ciudadId" />
                        </div>
                      </div>
                    </div>
                    <div class="my-2">
                      <input
                        type="text"
                        class="bg-gray-input text-gray form-control border-0"
                        placeholder="Nombre de la ubicación"
                        v-model="model.nombre"
                        id="nombre"
                      />
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="nombre" />
                        </div>
                      </div>
                    </div>
                    <div class="my-2">
                      <input
                        type="text"
                        class="bg-gray-input text-gray form-control border-0"
                        placeholder="Dirección de la ubicación"
                        v-model="model.direccion"
                        id="direccion"
                      />
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="direccion" />
                        </div>
                      </div>
                    </div>
                    <div class="my-2">
                      <button
                        class="btn bg-blue text-white rounded-pill w-100"
                        type="submit"
                      >
                        Agregar nueva ubicación
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent, onMounted, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ref } from "vue";
import { ErrorMessage, useField, useForm } from "vee-validate";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import sedes from "@/services/sedes.service";

export default defineComponent({
  name: "documents",
  components: { ErrorMessage },
  setup() {
    const ciudades = ref([]);
    const departamentos = ref([]);
    const paises = ref([]);
    const validationSchema = Yup.object().shape({
      nombre: Yup.string().required("Este campo es requerido"),
      ciudadId: Yup.string().required("Este campo es requerido"),
      paisId: Yup.string().required("Este campo es requerido"),
      departamentoId: Yup.string().required("Este campo es requerido"),
      direccion: Yup.string().required("Este campo es requerido"),
    });
    const {
      handleReset,
      handleSubmit,
      values: model,
    } = useForm({
      validationSchema,
    });
    useField("nombre", null, { initialValue: "" });
    useField("ciudadId", null, { initialValue: "" });
    useField("paisId", null, { initialValue: "" });
    useField("paises", null, { initialValue: "" });
    useField("departamentos", null, { initialValue: "" });
    useField("departamentoId", null, { initialValue: "" });
    useField("ciudades", null, { initialValue: "" });
    useField("direccion", null, { initialValue: "" });

    const submit = handleSubmit((values) => {
      sedes.crearSedes(values).then((data) => {
        console.log(data);
        handleReset();
        Swal.fire(
          "Mensaje",
          "La nueva sede se ah creado con exito",
          "success"
        ).catch((err) => {
          console.log(err.response.data.message);
          Swal.fire("Error", err.response.data.message, "error");
        });
      });
    });
    const traerPaises = () => {
      sedes.listarPaises().then((res) => {
        console.log(res);
        paises.value = res.data;
      });
    };
    const traerDepartamentos = () => {
      if (!model.paisId) return;
      sedes.listarDepartamentos(model.paisId).then((res) => {
        console.log(res);
        departamentos.value = res.data;
      });
    };
    const traerCiudades = () => {
      if (!model.departamentoId) return;
      sedes.listarCiudades(model.departamentoId).then((data) => {
        console.log(data);
        ciudades.value = data.data;
      });
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Documents", ["Pages", "Profile"]);
      traerPaises();
    });

    watch(
      () => model.paisId,
      () => {
        traerDepartamentos();
      }
    );
    watch(
      () => model.departamentoId,
      () => {
        traerCiudades();
      }
    );

    return { model, submit, ciudades, departamentos, paises };
  },
});
</script>
<style scoped>
.border-blue {
  border-color: #27aae1 !important;
}
.text-gray {
  color: #999999;
}
.bg-blue {
  background-color: #27aae1;
}
.bg-gray-input {
  background-color: #f4f8fb;
}
.fondo {
  background-image: url(/img/fondo_location.jpg);
  background-size: cover;
}
</style>
